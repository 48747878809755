@use '@fixed-size' as *;
@use '@typography' as *;
@use '@content' as *;
@use '@button' as *;
@use '@queries' as *;

.card-inventory {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: var(--cornerradius12);
  outline: 1px solid var(--cst-cards-item-primary-outline-default);
  outline-offset: -1px;
  background: var(--cst-cards-item-primary-background-default);
  box-shadow: var(--elevation-1);
  @include fixedSize(100%, 253px);

  &.disabled {
    opacity: 0.7;
    background: #f6f6f666;

    pointer-events: none;
  }

  @include min-1920-break {
    @include fixedSize(100%, 266px);
    gap: 10px;
    padding: 14px;
  }

  .top-slot-wrapper {
    @include fixedSize(120px, max-content);

    @include min-1920-break {
      @include fixedSize(135px, max-content);
    }
    position: relative;

    .top-slot {
      display: flex;
      @include singleFixedSize(100%);
      position: relative;

      &--right {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .top-slot-bottom {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
    }
  }

  .middle-slot {
    display: flex;
    width: 100%;
  }
  .bottom-slot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.item-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  justify-self: left;

  .item {
    z-index: 1;
    @include singleFixedSize(96px);
  }

  .item-rarity {
    position: absolute;

    @include singleFixedSize(120px);
  }
}

.item-info-container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      overflow: hidden;
      max-width: 120px;
      color: var(--cst-cards-item-primary-title-default);
      @include typography-s;
      @include weight-medium;

      @include min-1920-break {
        @include typography-base;
        max-width: 135px;
      }
    }

    .subtitle {
      color: var(--cst-cards-item-primary-type-text-default);
      width: inherit;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      overflow: hidden;
      transition: all 0.2s ease;

      @include typography-s;
      @include weight-medium;

      @include min-1920-break {
        @include typography-base;
        @include weight-medium;
      }
    }

    .price {
      display: flex;
      gap: 4px;
      align-items: center;

      &--current {
        @include typography-l;
        @include weight-bold;
        color: var(--cst-cards-item-page-newprice);

        @include min-1920-break {
          @include typography-xl;
        }
      }

      &--old {
        @include typography-s;
        @include weight-semi-bold;
        color: var(--cst-cards-item-secondary-oldpricetext-default);
      }
    }
  }
}

.item-properties-container {
  display: flex;
  align-items: center;
  width: max-content;
  align-self: flex-start;
  background: var(--cst-cards-shoping-item-background-tags);
  border-radius: var(--cornerradius8);
  padding: 6px;
  gap: 6px;

  @include fixedSize(max-content, 30px);

  @include min-1920-break {
    gap: 8px;
    padding: 8px;
  }

  .category,
  .rarity {
    @include singleFixedSize(14px);
  }

  &:empty {
    padding: 0;
  }
}

.item-paddings {
  padding: 12px 20px;
}

.spending-progress {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;

  .price {
    display: flex;
    align-items: center;
    gap: 2px;

    .current {
      @include typography-l;
      @include weight-bold;
      color: var(--cst-cards-shoping-item-new-price);
      @include min-1920-break {
        @include typography-xl;
      }
    }

    .total {
      @include typography-s;
      @include weight-semi-bold;
      color: var(--cst-cards-shoping-item-old-price);

      @include min-1920-break {
        @include typography-s;
      }
    }
  }

  .content {
    @include typography-s;
    @include weight-medium;
    @include fixedSize(100%, 89px);

    color: var(--cst-cards-shoping-item-title);

    @include min-1920-break {
      @include typography-base;

      @include fixedSize(100%, 87px);
    }
  }
}
